<form [formGroup]="formGroup">
    <div *ngIf="usePractices && !(!isMainForm && restIndication.value) && enableMedicalOrderDescription" [ngClass]="{'practices-container': isMainForm}">
        <app-autocomplete
            *ngIf="!id?.value"
            [form]="formGroup"
            controlName="practice"
            label="practice_to_prescribe"
            placeholder="prescription_medicine_placeholder"
            [items]="filteredPractices"
            [displayFn]="displayItemName"
            (optionSelectedEvent)="onSelectPractice($event)">
        </app-autocomplete>

        <mat-label *ngIf="id?.value && selectedPractices?.controls?.length > 0">{{ 'practices' | locale }}</mat-label>

        <app-item
            *ngFor="let practiceFormGroup of selectedPractices.controls; index as index"
            [itemText]="practiceFormGroup?.value?.name"
            iconName="test-tube"
            color="bg-dark"
            class="medium-height-icon"
            [index]="index"
            [suffix]="false"
            [displayTrashButton]="!id?.value"
            (removeEvent)="onRemovePractice($event)">
        </app-item>
    </div>

    <div *ngIf="enableMedicalOrderDescription" class="indicate-rest" formGroupName="restIndicationFormGroup">
        <span *ngIf="!isMainForm && restIndication.value" class="rest-indication">{{ "rest_indication" | locale }}</span>

        <ng-container *ngIf="isMainForm">
            <mat-checkbox
                *ngIf="!call.provider?.noRestIndication; else noRestIndication"
                color="primary"
                formControlName="restIndication">
                {{ "indicate_rest" | locale }}
            </mat-checkbox>

            <ng-template #noRestIndication>
              <span class="alert danger">
                  {{ "no_rest_indication" | locale }}
              </span>
            </ng-template>

            <mat-radio-group
                *ngIf="restIndication.value"
                formControlName="restTime"
                color="primary">
                <mat-radio-button [value]="24">24hs</mat-radio-button>
                <mat-radio-button [value]="48">48hs</mat-radio-button>
            </mat-radio-group>
        </ng-container>
    </div>

    <ng-container *ngIf="enableMedicalOrderDescription">
        <mat-label>{{ 'medical_order_description_label' | locale }}</mat-label>
        <mat-form-field class="textarea">
          <textarea
                matInput
                rows="5"
                [placeholder]="'medical_order_description_placeholder' | locale"
                [maxlength]="usesOwnPractices && this.selectedPractices.length > 0 ? 300 : null"
                formControlName="description">
            </textarea>
            <mat-hint [align]="'end'" *ngIf="usesOwnPractices && this.selectedPractices.length > 0">
                {{ description?.value?.length || 0 }}/{{ 300 }}
            </mat-hint>
            <mat-error>{{ description | errorMessage }}</mat-error>
        </mat-form-field>
    </ng-container>

    <mat-checkbox
        *ngIf="displayInstructionCheckbox"
        #instructionCheckbox
        [checked]="!enableMedicalOrderDescription"
        color="primary"
        (change)="onChangeCheckbox($event.checked)">
        {{ "medical_order_add_instructions" | locale }}
    </mat-checkbox>

    <ng-container *ngIf="displayInstructions || !displayInstructionCheckbox">
        <mat-label>{{ 'medical_order_instructions' | locale }}</mat-label>
        <mat-form-field class="textarea">
            <textarea
                matInput
                rows="5"
                [placeholder]="'medical_order_instructions' | locale"
                formControlName="instructions">
            </textarea>
            <mat-error>{{ instructions | errorMessage }}</mat-error>
        </mat-form-field>
    </ng-container>

    <button
        *ngIf="isMainForm"
        mat-button
        color="accent"
        [disabled]="!description.value?.trim() && (!instructions.value?.trim() || !instructionCheckbox?.checked) && selectedPractices?.value.length === 0"
        (click)="submitForm()">
        {{ 'medical_order_add' | locale }}
    </button>
</form>
