import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatCheckbox } from '@angular/material/checkbox';
import * as _ from 'lodash';
import { Observable, of, Subject, Subscription } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, map, startWith, switchMap } from 'rxjs/operators';
import { Call } from 'src/app/models/call.model';
import { MedicalOrder } from 'src/app/models/medical-order.model';
import { Practice } from 'src/app/models/practice.model';
import { LocaleService } from 'src/app/services/locale.service';
import { PracticeService } from 'src/app/services/practice.service';
import { autocompleteObjectValidator } from 'src/app/utils/autocomplete-validators.function';
import { displayItemName } from 'src/app/utils/display-fields.utils';

@Component({
  selector: 'app-medical-order-form',
  templateUrl: './medical-order-form.component.html',
  styleUrls: ['./medical-order-form.component.css']
})
export class MedicalOrderFormComponent implements OnInit, OnDestroy {
  @Input()
  call: Call;

  @Input()
  formGroup: FormGroup;

  @Input()
  displayInstructionCheckbox = true;

  @Input()
  enableRestIndicationSubject: Subject<boolean>;

  @Input()
  resetObservable: Observable<void>;

  @Input()
  enableMedicalOrderDescription = true;

  @Input()
  practicesToOmitFromListSubject: Subject<string[]>;

  @Output()
  onSubmitForm = new EventEmitter<MedicalOrder>();

  @Output()
  formChangeEvent = new EventEmitter<void>();

  @Output()
  selectPracticeEvent = new EventEmitter<string>();

  @Output()
  removePracticeEvent = new EventEmitter<{practice: Practice, isPracticesListEmpty: boolean}>();

  @ViewChild("instructionCheckbox")
  instructionCheckbox: MatCheckbox;

  displayItemName = displayItemName;
  isMainForm: boolean;
  displayInstructions = false;

  usePractices: boolean;
  usesOwnPractices: boolean;
  filteredPractices: Observable<Practice[]> = of([]);
  practicesToOmitFromList: string[] = [];

  subscription = new Subscription();

  constructor(
    private formBuilder: FormBuilder,
    private localeService: LocaleService,
    private practiceService: PracticeService,
  ) { }

  ngOnInit(): void {
    this.isMainForm = !this.formGroup;
    this.usePractices = this.call.provider.usesPractices;
    this.usesOwnPractices = this.call.provider.usesOwnPractices;
    this.buildForm();

    if(!this.enableMedicalOrderDescription) this.displayInstructions = true;

    this.enableRestIndicationSubject?.subscribe(value => {
      if(value) this.restIndication.enable({ emitEvent: false });
      else this.restIndication.disable({ emitEvent: false });
    });

    this.resetObservable?.subscribe(() => this.resetForm());

    this.practicesToOmitFromListSubject?.subscribe(value => {
      this.practicesToOmitFromList = value;
    })
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }

  buildForm(): void {
    if(!this.formGroup) {
      this.formGroup = this.formBuilder.group({
        description: { value: '', disabled: this.usesOwnPractices },
        instructions: '',
        restIndicationFormGroup: this.formBuilder.group({
          // If call was already setted as rest indication
          restIndication: { value: false, disabled: this.call?.restIndication },
          restTime: 24,
        }),
        practice: [null, [autocompleteObjectValidator(false)]],
        selectedPractices: this.formBuilder.array([]),
      });
    } else {
      if(this.usesOwnPractices && !this.restIndication.value && !this.selectedPractices.value.length) this.description.disable();
    }

    if(this.isMainForm) {
      this.formGroup.valueChanges.subscribe(() =>
        this.formChangeEvent.emit()
      );
    }

    this.subscribeToFormChanges();
  }

  submitForm(): void {
    this.validateForm();
    if(this.formGroup?.valid) {
      const medicalOrder: MedicalOrder = {
        description: this.description.value?.trim(),
        ...(this.instructionCheckbox?.checked && { instructions: this.instructions.value?.trim() }),
        ...(this.restIndication.value && { restIndication: true }),
        ...(!_.isEmpty(this.selectedPractices.value) && { practices: this.selectedPractices.value })
      }
      this.onSubmitForm.emit(medicalOrder);
      this.resetForm();
    }
  }

  resetForm(): void {
    this.formGroup.reset({
      description: '',
      instructions: '',
      restIndicationFormGroup: {
        restIndication: false,
        restTime: 24,
      },
      practice: null,
    });

    this.selectedPractices.clear();

    Object.keys(this.formGroup.controls).forEach((key) =>
      this.formGroup.controls[key]?.setErrors(null)
    );
  }

  validateForm(): void {
    Object.keys(this.formGroup.controls).forEach((key) =>
      this.formGroup.controls[key]?.updateValueAndValidity()
    );
  }

  onChangeCheckbox(checked: boolean): void {
    this.displayInstructions = checked;
  }

  async getRestIndicationText(hours: string | number): Promise<string> {
    return (await this.localeService.getStringByLocale('rest_indication_template', this.call.language.code))
    .replace(new RegExp('__PATIENT_NAME__', 'g'), this.call.patient.name)
    .replace(new RegExp('__MOTIVE__', 'g'), this.call.motive || this.call.diagnosis?.motive || (await this.localeService.getStringByLocale('motive', this.call.language.code))?.toUpperCase())
    .replace(new RegExp('__HOURS__', 'g'), hours?.toString());
  }

  onSelectPractice(practice: Practice): void {
    this.practice.patchValue(null);
    this.selectedPractices.push(this.formBuilder.group(practice));
    this.enableRestIndicationSubject?.next(false);
    if (this.usesOwnPractices) this.description.enable();
    this.selectPracticeEvent.next(practice.id);
  }

  onRemovePractice(index: number): void {
    const practice = this.selectedPractices.at(index).value as Practice;
    this.selectedPractices.removeAt(index);
    if (this.usesOwnPractices && this.selectedPractices.length === 0) {
      this.description.patchValue("");
      this.description.disable();
    }
    this.removePracticeEvent.emit({practice, isPracticesListEmpty: _.isEmpty(this.selectedPractices.controls)});
  }

  subscribeToFormChanges(): void {
    this.subscription.add(this.restIndication.valueChanges.subscribe(async value => {
      if(value) {
        const restIndicationText = await this.getRestIndicationText(this.restTime.value);
        if (this.usesOwnPractices) this.description.enable();
        this.description.patchValue(restIndicationText);
        this.practice.disable();
      } else {
        if (this.usesOwnPractices) this.description.disable();
        this.description.patchValue('');
        this.practice.enable();
      }
    }));

    this.subscription.add(this.restTime.valueChanges.subscribe(async value => {
      if(this.restIndication.value) {
        const restIndicationText = await this.getRestIndicationText(value);
        this.description.patchValue(restIndicationText);
      }
    }));

    this.subscribeToPracticeChanges();
  }

  subscribeToPracticeChanges(): void {
    if(!this.formGroup || !this.practice) return;
    this.filteredPractices = this.practice.valueChanges.pipe(
      startWith(''),
      debounceTime(400),
      distinctUntilChanged(),
      switchMap((val: string) => this.getFilteredPractices(val || '')),
      map(practices => practices.filter(practice => !this.practicesToOmitFromList.includes(practice.id))),
    );
  }

  private getFilteredPractices(value: string): Observable<Practice[]> {
    const filterValue = value.toLowerCase();
    if(filterValue.length < 3) return of([]);
    return this.practiceService.getByName(
      this.usesOwnPractices ? this.call.provider.id : null, filterValue
    )
    .pipe(catchError(() => []));
  }

  get id(): FormControl<string> { return this.formGroup.get("id") as FormControl }
  get description(): FormControl<string> { return this.formGroup.get("description") as FormControl }
  get instructions(): FormControl<string> { return this.formGroup.get("instructions") as FormControl }
  get restIndication(): FormControl<boolean> { return this.formGroup.get('restIndicationFormGroup.restIndication') as FormControl }
  get restTime(): FormControl<24 | 48> { return this.formGroup.get('restIndicationFormGroup.restTime') as FormControl }
  get practice(): FormControl<string> { return this.formGroup.get('practice') as FormControl }
  get selectedPractices(): FormArray { return this.formGroup.get('selectedPractices') as FormArray }
}
