<h2 class="count-calls">{{ 'call' | locale }} {{ index + 1 }} {{ 'of' | locale }} {{ totalCalls }}</h2>

<div class="call-info">
    <h2 class="call-date">{{ 'call' | locale }} {{ 'of' | locale }} {{ call.createdAt | momentDate : "LL HH:mm" }}hs</h2>

    <span *ngIf="isFamilyMemberCall" class="family-member-call">
        {{ ('family_member_call' | locale) + ': ' + (call.caller?.name) }}
    </span>
</div>

<h1>{{ 'professional_name' | locale }}</h1>
<p>{{ call.doctor.name || '-' }} ({{ call.doctor.enrollmentType }}{{ call.doctor.enrollment ? '. ' + call.doctor.enrollment : ''}})</p>
<mat-divider></mat-divider>

<h1>{{ 'specialty' | locale }}</h1>
<p> {{ call.specialty?.name || '-'  }}</p>
<mat-divider></mat-divider>

<h1>{{ 'call_provider' | locale }}</h1>
<p> {{ call.provider?.name || '-'  }}</p>
<mat-divider></mat-divider>

<h1>{{ 'diagnosis_motive_label' | locale }}</h1>
<p> {{ call.diagnosis?.motive || call.motive || '-' }}</p>
<mat-divider></mat-divider>

<h1>{{ 'medical_history' | locale }}</h1>
<p *ngIf="call.diagnosis?.patientRecords; else records">{{ call.diagnosis.patientRecords }}</p>
<ng-template #records>
    <ul class="aligned-list">
        <li *ngFor="let record of medicalHistory?.split(',')">{{ record }}</li>
    </ul>
</ng-template>
<p *ngIf="!call.diagnosis?.patientRecords && !medicalHistory">-</p>
<mat-divider></mat-divider>

<h1>{{ 'progress_notes' | locale }}</h1>
<mat-form-field class="textarea">
    <textarea
        matInput
        rows="4"
        [value]="call.diagnosis?.text || '-'"
        placeholder="-"
        readonly
        >
      </textarea>
</mat-form-field>

<h1>{{ 'diagnosis' | locale }}</h1>
<p> {{ diagnosis || '-' }}</p>
<mat-divider></mat-divider>

<h1>{{ 'treatment' | locale }}</h1>
<mat-form-field class="textarea">
    <textarea
        matInput
        rows="4"
        [value]="call.diagnosis?.treatment || '-'"
        placeholder="-"
        readonly
        >
      </textarea>
</mat-form-field>

<ng-container *ngIf="call.prescriptions && call.prescriptions.length > 0">
    <h1>{{ 'medicines_prescription' | locale }}</h1>
    <ng-container *ngFor="let prescription of call.prescriptions">
        <ul *ngFor="let medicine of prescription.medicines" class="aligned-list">
            <li>{{ medicine.active }} {{medicine.pot}}. ({{ medicine.quantity }}) {{ medicine.instructions }}</li>
            <span class="badge {{ prescription.status | lowercase }}">{{ 'hc_medical_prescriptions_' + prescription.status | lowercase | locale }}</span>
            <ul *ngIf="medicine.pot.trim() === 'No Clasificada'" class="aligned-list">
                <li *ngFor="let component of medicine.drugs?.split('+')">{{ component }}</li>
            </ul>
        </ul>
    </ng-container>
    <mat-divider></mat-divider>
</ng-container>

<ng-container *ngIf="call.medicalOrders && call.medicalOrders.length > 0">
    <h1>{{ 'hc_medical_orders_label' | locale }}</h1>
    <ul *ngFor="let order of call.medicalOrders" class="aligned-list">
        <li *ngIf="order.practices?.length > 0">
            <ng-container *ngFor="let practice of order.practices; last as last">
                {{ practice.name }}<span>{{ !last ? ', ' : '.' }}</span>
            </ng-container>
        </li>
        <li *ngIf="order.description?.trim().length > 0">{{ order.description }}</li>
        <li *ngIf="order.instructions?.trim().length > 0">{{ order.instructions }}</li>
    </ul>

    <ng-container *ngIf="call.restIndication">
        <mat-divider></mat-divider>
        <mat-checkbox
            class="read-only"
            [checked]="true"
            [disabled]="true">
            {{ 'rest_indication' | locale }}
        </mat-checkbox>
    </ng-container>
</ng-container>
